import React from 'react'
import './index.css'
const TeamMember = props => {
    return ( 
            <div className="col-sm-3 mb-4">
                <div className='mainblockimg' onClick={()=>props.url && window.open(props.url, '_blank')}>
                    <img className="image" src={props.image} alt="Avatar"  />
                    <div className="overlay5">
                        <div className="text">{props.name}<br/><span className='designation'>{props.desi}</span></div>
                    </div>
                </div>
            </div> 
    )
}

const OurTeam = props => {
    return (
        <div className="row ourTeam mt-5 pl-5 pr-5 mr-0 ml-0">
            {props.teamMembers.map(teamMember => <TeamMember url={teamMember.url} image={teamMember.image} name={teamMember.name} desi={teamMember.desi}/>)}
        </div>
    )
};

export default OurTeam;