import React from 'react'
import OurTeam from '../components/OurTeam'
import SanjivAgarwal from '../images/ourteam/SanjivAgarwal.jpg'
import RajivAgarwal from '../images/ourteam/RajivAgarwal.jpg'
import GazanfarIbrahim from '../images/ourteam/GazanfarIbrahim.jpg'
import VasudevanSuresh from '../images/ourteam/VasudevanSuresh.jpg'
import AakashVerma from '../images/ourteam/AakashVerma.jpg'
import VikasChopra from '../images/ourteam/VikasChopra.jpg'
import SiddhantSharma from '../images/ourteam/SiddhantSharma.jpg'
import BhavikVora from '../images/ourteam/BhavikVora.jpg'
import HarshAgarwal from '../images/ourteam/HarshAgarwal.jpg'
import MdTariqueEbrahim from '../images/ourteam/MdTariqueEbrahim.jpg'
import RajKapoorSingh from '../images/ourteam/RajKapoorSingh.jpg'
import RamanMahesh from '../images/ourteam/RamanMahesh.jpg'
import SandrineClarac from '../images/ourteam/SandrineClarac.jpg'
import UmangAgarwal from '../images/ourteam/UmangAgarwal.jpg'
import RajarshiDe from '../images/ourteam/RajarshiDe.jpg'
import RenuKundaliya from '../images/ourteam/RenuKundaliya.jpg'
import Layout from '../layouts'

const teamMembers = [
  {
    name: 'Sanjiv Agarwal',
    desi: 'Founder & CEO',
    image: SanjivAgarwal,
    url: "https://www.linkedin.com/in/sanjiv-agarwal-53b805b9"
  },
  {
    name: 'Rajiv Agarwal',
    desi: 'Director',
    image: RajivAgarwal,
    url: ""
  },
  {
    name: 'Gazanfar Ibrahim',
    desi: 'CMO & MD',
    image: GazanfarIbrahim,
    url: "https://www.linkedin.com/in/gazanfar-ibrahim-86935b21"
  },
  {
    name: 'Vasudevan Suresh',
    desi: 'President, GGIF',
    image: VasudevanSuresh,
    url: "https://www.linkedin.com/in/suresh-vasudevan-349253157"
  },
  {
    name: 'Harsh Agarwal',
    desi: 'Managing Director - International',
    image: HarshAgarwal,
    url: "https://www.linkedin.com/in/harsh3442"
  },
  {
    name: 'Umang Agarwal',
    desi: 'Executive Director',
    image: UmangAgarwal,
    url: "https://www.linkedin.com/in/umangag"
  },
  {
    name: 'Raj Kapoor Singh',
    desi: 'Director of Sales',
    image: RajKapoorSingh,
    url: "https://www.linkedin.com/in/raj-kapoor-singh-6b428816"
  },
  {
    name: 'Sandrine Clarac',
    desi: 'Director - Marketing & International Sales',
    image: SandrineClarac,
    url: "https://www.linkedin.com/in/sandrine-clarac"
  },
  {
    name: 'Bhavik Vora',
    desi: 'Director of Finance & Accounting',
    image: BhavikVora,
    url: "https://www.linkedin.com/in/bhavik-vora-ca-acca-dip-in-ifrs-6a93bb53"
  },
  {
    name: 'Rajarshi De',
    desi: 'Area Director',
    image: RajarshiDe,
    url: ""
  },
  {
    name: 'Raman Mahesh',
    desi: 'Area Director',
    image: RamanMahesh,
    url: ""
  },
  {
    name: 'Md Tarique Ebrahim',
    desi: 'Area Director',
    image: MdTariqueEbrahim,
    url: "https://www.linkedin.com/in/tarique-ebrahim-5654332a"
  },
  {
    name: 'Aakash Verma',
    desi: 'Associate Director',
    image: AakashVerma,
    url: "https://www.linkedin.com/in/aakash-verma-53a272b6"
  },
  {
    name: 'Vikas Chopra',
    desi: 'Associate Director',
    image: VikasChopra,
    url: "https://www.linkedin.com/in/vikas-chopra-76083628"
  },
  {
    name: 'Renu Kundaliya',
    desi: 'Associate Director',
    image: RenuKundaliya,
    url: "https://www.linkedin.com/in/renu-kundaliya-1a4190122"
  },
]

const TeamMembersPage = props => {
  return (
    <Layout>
      <OurTeam teamMembers={teamMembers} />
    </Layout>
  )
}

export default TeamMembersPage
